<template>
  <div>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        route_name="gestion_de_la_structure__dashboard"
      />
    </v-row>

    <v-row v-if="mounted" no-gutters class="informationsdemonclub__layout">
      <v-col cols="12" color="blue" sm="4" lg="3" class="scrollable">
        <v-row class="informationsdemonclub__sidebar" no-gutters>
          <v-col cols="12" class="informationsdemonclub__sidebar-avatar">
            <Avatar
              editable
              classes="__xl"
              :clubID="currentStructureId"
              :userID="null"
              :changeLink="canChangeAvatar"
            ></Avatar>
          </v-col>

          <v-col cols="12" class="informationsdemonclub__sidebar-navigation">
            <Navigation></Navigation>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="8" lg="9" class="informationsdemonclub__content">
        <v-row
          class="informationsdemonclub__content-content-view h-100"
          no-gutters
        >
          <transition name="fade" mode="out-in">
            <router-view @updateTitle="updateTitle"></router-view>
          </transition>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Avatar from "@/components/Avatar.vue";
import Navigation from "@/views/GestionDesSaisons/GestionDesSaisons__Navigation.vue";
import SubBar from "@/components/Common/Common__SubBar.vue";

export default {
  data: () => ({
    subBarTitle: "Gestion de la saison",
    saisonName: "2010-2020",
    mounted: false,
  }),

  components: { Avatar, Navigation, SubBar },

  created() {
    this.setAppTitle({ title: "Gestion de la saison" });
    this.saisonName = this.selectedSaison.NomSaison;
    this.mounted = true;
  },

  computed: {
    ...mapGetters("saisons", ["selectedSaison"]),
    ...mapGetters("structure", ["currentStructure", "currentStructureId"]),

    canChangeAvatar() {
      return this.currentStructure.STR_Structure.LIST_StructureType.EST_Club
    },
  },

  methods: {
    ...mapActions("app", ["setAppTitle"]),

    updateTitle(title) {
      this.subBarTitle = title ;
      // this.subBarTitle = title + " - " + this.saisonName;
    },
  },
};
</script>

<style lang="scss"></style>
