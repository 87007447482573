<template>
  <ul
    class="moncompte__navigation-body d-flex flex-column justify-space-between text-center pl-0"
  >
    <liNav
      title="Informations génerales"
      module_name="gestion_des_saisons__informations_generales"
      auth
    />
    <!-- <liNav
      title="Documents à joindre"
      module_name="gestion_des_saisons__documents_a_joindre"
      auth
    /> -->
    <liNav
      title="Gestion des salariés"
      module_name="gestion_des_saisons__gestion_des_salaries"
      auth
    />
    <liNav
      title="Cotisation licence"
      module_name="gestion_des_saisons__cotisations_des_licences"
      auth
    />
    <liNav
      title="Représentants AG nationale"
      module_name="gestion_des_saisons__representants_ag_national"
      auth
    />
  </ul>
</template>

<script>
import liNav from '@/components/Common/Navigation__item';
import { mapGetters } from 'vuex';

export default {
  components: { liNav },

  computed: {
    ...mapGetters('structure', ['currentStructureType']),

    structureType() {
      return this.currentStructureType.SlugTypeStructure;
    },
  },
};
</script>

<style lang="scss"></style>
